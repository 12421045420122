import './App.less';
import { useRoutes } from 'react-router-dom'
import { routes } from './config/router'
import BackTop from './pages/Components/BackTop'

function App() {
  return (
    <div className="main">
     {useRoutes(routes)}
     <BackTop />
    </div>
  );
}

export default App;
