import { navList } from '../../config/index'
import { useLocation, useNavigate } from 'react-router-dom'
import './index.less'
import { useEffect, useState } from 'react'
import { throttle } from 'lodash-es'

export default ()=>{
  const navigate = useNavigate();
  const locationData = useLocation()
  const { pathname } = locationData

  const [ hide, setHide ] = useState(false)
  const onClickFun = (item) => {
    const { url, link } = item
    if(url) {
      navigate(url)
    }else {
      window.open(link)
    }
  }
  const getTopPx = throttle(()=>{
    if(window.scrollY > 1) {
      setHide(true)
    }else {
      setHide(false)
    }
  }, 200)
  useEffect(()=>{
    window.addEventListener('scroll',()=>{
      getTopPx()
    })
  }, [])
  
  return <div className={`header ${hide ? 'hide' : ''} animate__animated animate__bounce`}>
    <div className="logo" />
    <div className="nav">
      {
        navList.map((item, index)=>{
          const { title, url } = item
          let isActive = pathname === url
          if(url !== '/') {
            isActive = pathname.includes(url)
          }
          return <div key={`nav${index}`} onClick={()=>{onClickFun(item)}} className={isActive ? 'active' : ''}>{title}</div>
        })
      }
    </div>
  </div>
}