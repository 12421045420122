import React, { Suspense, lazy } from 'react';
import BasicLayout from '../pages/BasicLayout'

const lazyLoad = (src) => <Suspense fallback={<></>}>{React.createElement(lazy(src))}</Suspense>;
export const routes = [
  {
    path: '/',
    element: <BasicLayout />,
    children: [
      {
        index: true,
        element: lazyLoad(() => import('../pages/Home'))
      },
      {
        path: 'technology',
        element: lazyLoad(() => import('../pages/Technology'))
      },
      {
        path: 'technology/:to',
        element: lazyLoad(() => import('../pages/Technology'))
      },
      {
        path: 'businessprogress',
        element: lazyLoad(() => import('../pages/Businessprogress'))
      },
      {
        path: 'businessprogress/:to',
        element: lazyLoad(() => import('../pages/Businessprogress'))
      },
      {
        path: 'news',
        element: lazyLoad(() => import('../pages/News'))
      },
    ]
  }
];
