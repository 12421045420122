import './index.less'
import { navList } from '../../config/index'
import { useLocation, useNavigate } from 'react-router-dom'
import { Image } from 'antd'
import { useState } from 'react'
import gongzhonghao from '../../images/gongzhonghao.jpg'

export default () => {
  const navigate = useNavigate();
  const locationData = useLocation()
  const { pathname } = locationData

  const onClickFun = (item) => {
    const { url, link } = item
    if (url) {
      navigate(url)
      window.scrollTo(0, 0)
    } else {
      window.open(link)
    }
  }
  const [weixinVisible, setWeixinVisible] = useState(false);

  return <div className="footer">
    <div className='con'>
      <div className="nav">
        {
          navList.map((item, index) => {
            const { title, url } = item
            let isActive = pathname === url
            if (url !== '/') {
              isActive = pathname.includes(url)
            }
            return <div key={`nav${index}`} onClick={() => { onClickFun(item) }} className={isActive ? 'active' : ''}>{title}</div>
          })
        }
      </div>
      <div className='info info1'>
        <p className='contact'><span>联系我们</span>contact@kargobot.ai</p>
        <p className='phone'><span>公司电话</span>010-53677003</p>
      </div>
      <div className='info info2'>
        <div><span>公司地址</span>
          北京海淀区北清路81号院A1座5层
          <p className='bottom'>天津市滨海新区环普工业园A03</p>
        </div>
      </div>
    </div>
    <div className='copyright'>
      {window.document.domain === 'kargo-bot.com' && <><span>KargoBot 2023 版权所有 <span className='police' /><a href="https://beian.mps.gov.cn/#/query/webSearch?code=12011602300141" rel="noreferrer" target="_blank">津公网安备12011602300141</a> 津ICP备2023005323号-1</span>
        <span className='company'>天津卡尔动力科技有限公司</span>
      </>}
      <div className='right-icon'>
        <a href='https://www.linkedin.com/company/kargobot/' target='_blank'>
          <span className='link' />
        </a>
        <span className='weixin' onClick={() => {
          setWeixinVisible(true)
        }} />
      </div>
    </div>
    <Image
      style={{
        display: 'none',
      }}
      src={gongzhonghao}
      preview={{
        visible: weixinVisible,
        onVisibleChange: (value) => {
          setWeixinVisible(value);
        },
        className: 'footerpreview'
      }}
    />
  </div>
}