// 导航菜单
export const navList = [
  {
    title: '首页',
    url: '/'
  },
  {
    title: '核心技术',
    url: '/technology'
  },
  {
    title: '商业进展',
    url: '/businessprogress'
  },
  {
    title: '加入我们',
    link: 'https://kargobot.jobs.feishu.cn/index'
  },
  {
    title: '媒体新闻',
    url: '/news'
  },
]